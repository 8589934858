import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/js/pages/Home'
import Imprint from '@/js/pages/Imprint'
import Changelog from'@/js/pages/Changelog'



Vue.use(VueRouter)

const routes = [
    {
        name: 'home',
        path: '/',
        meta: { layout: 'app'},
        component: Home,
    },
    {
        name: 'imprint',
        path: '/imprint',
        meta: { layout: 'app'},
        component: Imprint,
    },
    {
        name: 'changelog',
        path: '/changelog',
        meta: { layout: 'app'},
        component: Changelog,
    }
]

let router = new VueRouter({
    mode: "history",
    routes
})

export default router
