<template>
    <div>
        <header-bar />
        <div id="content" class="flex-1 container mx-auto p-8">
        <slot />
        </div>
        <footer-bar />
    </div>
</template>
<script>
import FooterBar from '@/js/components/footer'
import HeaderBar from '@/js/components/header'

export default {
    name: 'App',
    components: {
        HeaderBar,
        FooterBar
    }
}
</script>
