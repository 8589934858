<template>
    <div class="py-10">
        <div class="container mx-auto">
            <div class="text-center px-0 lg:px-0">
                <h1 class="text-6xl mb-2 text-white">GAME OVER</h1>
                <div class="py-2">
                    <button type="button" class="nes-btn is-disabled w-56 uppercase">New Game</button>
                </div>
                <div class="py-2">
                    <button type="button" class="nes-btn is-disabled w-56 uppercase">Scoreboard</button>
                </div>
                <div class="py-2">
                    <button type="button" class="nes-btn is-disabled w-56 uppercase">Quit</button>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Home',
        components: {
        },
        beforeMount() {
            let audio = new Audio('sound/gameover.wav');
            if (audio !== undefined) {
                audio.then(_ => {

                }).catch(error => {
                });
            }

        }

    }
</script>
