import './bootstrap'
import Vue from 'vue'
import Routes from '@/js/routes'
import App from '@/js/views/App'

import '../../node_modules/nes.css/css/nes.min.css'
import AppLayout from '@/js/layouts/App.vue'


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.component('app-layout', AppLayout)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    router: Routes,
    render: h => h(App),
});
export default app
