<template>
    <footer class="fixed h-12 w-full bottom-0 bg-black">
        <div class="container mx-auto px-2">
            <div class="flex w-full">
                <div class="text-left w-full py-2">
                </div>
                <div class="text-center w-full py-3 text-sm">Made with <i class="nes-icon heart is-small"></i></div>
                <div class="text-right w-full">
                    <router-link to="/imprint" tag="button" class="nes-btn text-xs is-primary mb-10"></></router-link>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: 'Footer',
    components: {
    }
}
</script>
