<template>
    <div class="py-10">
        <div class="container mx-auto">
            <div class="nes-container is-dark with-title">
                <p class="title">Changelog</p>
                <div class="nes-container with-title is-dark">
                    <p class="title">09.06.2020</p>
                    <ul>
                        <li>* Add Changelog</li>
                        <li>* Add Changelog</li>
                    </ul>
                </div>
                <div class="lists">
                    <ul class="nes-list is-circle">
                        <li>Good morning.</li>
                        <li>Thou hast had a good night's sleep, I hope.</li>
                        <li>Thou hast had a good afternoon</li>
                        <li>Good night.</li>
                    </ul>
                </div>


                <div class="nes-container with-title is-dark">
                    <p class="title">08.06.2020</p>
                    <div class="lists">
                        <ul class="nes-list is-disc">
                            <li>Good morning.</li>
                            <li>Thou hast had a good night's sleep, I hope.</li>
                            <li>Thou hast had a good afternoon</li>
                            <li>Good night.</li>
                        </ul>
                    </div>

                    <div class="lists">
                        <ul class="nes-list is-circle">
                            <li>Good morning.</li>
                            <li>Thou hast had a good night's sleep, I hope.</li>
                            <li>Thou hast had a good afternoon</li>
                            <li>Good night.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <router-link to="/" tag="button" class="nes-btn text-xs mb-10">Back</router-link>
            <div class="lists">
                <ul class="nes-list is-circle">
                    <li>Good morning.</li>
                    <li>Thou hast had a good night's sleep, I hope.</li>
                    <li>Thou hast had a good afternoon</li>
                    <li>Good night.</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Imprint',
        components: {
        }
    }
</script>
