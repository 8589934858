<template>
    <header class="p-4">
        <nav class="nav flex flex-wrap items-center justify-between px-4">
            <div class="flex flex-no-shrink items-center mr-6 py-3 text-grey-darkest">
                <span class="nes-text is-disabled text-lg">Score: 000000</span>
            </div>
        </nav>
    </header>
</template>
<script>
    export default {
        name: 'Header',
        components: {
        }
    }
</script>
